import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./signup/signup.module').then(m => m.SignupPageModule)
  },
  {
    path: 'signup',
    loadChildren: () => import('./signup/signup.module').then(m => m.SignupPageModule)
  },
  {
    path: 'home',
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule)
  }
  ,
  {
    path: 'importer',
    loadChildren: () => import('./importer/importer.module').then( m => m.ImporterModule)
  },
  {
    path: 'transporter',
    loadChildren: () => import('./transporter/transporter.module').then( m => m.TransporterModule)
  },
  {
    path: 'signup',
    loadChildren: () => import('./signup/signup.module').then( m => m.SignupPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'profileedit',
    loadChildren: () => import('./modal/profileedit/profileedit.module').then( m => m.ProfileeditPageModule)
  },
  {
    path: 'searchloadfilter',
    loadChildren: () => import('./modal/searchloadfilter/searchloadfilter.module').then( m => m.SearchloadfilterPageModule)
  },
  {
    path: 'searchtruckfilter',
    loadChildren: () => import('./modal/searchtruckfilter/searchtruckfilter.module').then( m => m.SearchtruckfilterPageModule)
  },
  {
    path: 'forgotpassword',
    loadChildren: () => import('./modal/forgotpassword/forgotpassword.module').then( m => m.ForgotpasswordPageModule)
  },
  {
    path: 'bid',
    loadChildren: () => import('./modal/bid/bid.module').then( m => m.BidPageModule)
  },
  {
    path: 'myloadbids',
    loadChildren: () => import('./modal/myloadbids/myloadbids.module').then( m => m.MyloadbidsPageModule)
  },
  {
    path: 'uploadimage',
    loadChildren: () => import('./modal/uploadimage/uploadimage.module').then( m => m.UploadimagePageModule)
  }
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
