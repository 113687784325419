import { Injectable } from '@angular/core';

import CryptoJS from 'crypto-js';

import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';

import { Storage } from '@ionic/storage-angular';


@Injectable({
  providedIn: 'root'
})
export class ApiService {
    
  login = false;
  loginUserId = 0;
  accessToken = "";
  extrUrl = 'https://www.boxlogi.com/';
  baseUrl = this.extrUrl + 'api1/requestHandler';
  apikey = "AS4664:>:<'{}{SDdsds2#$1u9%7*##@!&&**#@@$)_++*QWE+KAS6ka346sda0sd3(64454";
  hash = "";
  type:any = {1:"Dry", 2:"HC", 3:"Tank", 4:"Dry Flexi", 5:"Open Top", 6:"Flatrack"};
  paymentterms:any = {
    0: "Cash",
    1: "40 days",
    2: "60 days"
  }
  
  headers;
  
  constructor(private storage: Storage, private http: HttpClient) {
        this.hash = this.createhash();
      
        this.storage.create();  
        Promise.all([this.storage.get("accessToken"), this.storage.get("userId")]).then((values : any) => {
                  this.accessToken = values[0];
                  this.loginUserId = values[1];
                 
                    if(this.accessToken == null) { this.accessToken = ""; }
                    
                    //console.log(this.accessToken);
                     
                    this.headers =  {
                            headers: new HttpHeaders()
                           .set("Content-type", "application/x-www-form-urlencoded") 
                           .set("Accept", "application/json")
                           .set("X-ACCESS-TOKEN", this.accessToken)
                           .set("X-API-TOKEN" , this.hash), withCredentials: true
                    }
        }); 
   }
   
  
  
  fetchData(body){
    return this.http.post(this.baseUrl,
      body
      , this.headers)
  }
  
  createhash(){
      let time1 = new Date();
      let time2 = time1.getTime();
      let time = CryptoJS.SHA256("qt"+time2).toString(CryptoJS.enc.Hex);
      let hash = CryptoJS.SHA1(this.apikey+time).toString(CryptoJS.enc.Hex);
      hash = CryptoJS.MD5(hash).toString(CryptoJS.enc.Hex)+time2;
      return hash;
  }
   
  
}
