import { Injectable } from '@angular/core';
import {Capacitor} from '@capacitor/core';
import { PushNotifications } from '@capacitor/push-notifications';
import { Device } from '@capacitor/device';
import { Storage } from '@ionic/storage-angular';
import { HttpClient } from '@angular/common/http'; 
import { ApiService } from '../service/api.service';


@Injectable({
    providedIn: 'root'
})

export class NotificationsService {
    
    login = false;
    
    constructor(private api: ApiService, public httpClient: HttpClient, private storage: Storage) { }
    
    initPush() {
        if (Capacitor.getPlatform() !== 'web') {
            this.storage.create();  
            this.registerPush();
        }
    }
    
    private registerPush() {
        
        PushNotifications.requestPermissions().then(permission => {
            if (permission.receive === 'granted') {
                PushNotifications.register();
            }
            else {
                // If permission is not granted
            }
        });
        
        PushNotifications.addListener('registration', (token) => {
            console.log(token);
            Promise.all([this.storage.get("login"), this.storage.get("notification")]).then(values => { 
                    this.login = values[0];
                    let notificationt = values[1];
                    if(this.login && !notificationt){
                        this.sendNotificationToken(token.value);
                    }
            });
        });
        
        PushNotifications.addListener('registrationError', (err)=> {
            console.log(err);
        }); 
        
        PushNotifications.addListener('pushNotificationReceived', (notifications) => {
            console.log(notifications);
        });
    
    }
    
    async sendNotificationToken(token){
        const device = await Device.getId();
        let query = "device=" + device.uuid +
                        "&token=" + token +
                        "&action=saveNotificationToken";
            this.api.fetchData(query).subscribe((data:any) => {
                if(data){
                    if(data['status']){
                        this.storage.set('notification', true);
                    }
                }
        }, error => { 
           // console.log(error);
        });  
    }
}